<template>
  <div class="ml-5 mr-10">
    <v-row>
      <v-col class="my-5">
        <v-card dark width="100%" rounded="lg">
          <v-card-text>
            <v-tabs>
              <v-tabs-slider color="brown"></v-tabs-slider>
              <v-tab
                ><v-icon left>mdi-gesture-tap-button</v-icon> Buttons</v-tab
              >
              <v-tab
                ><v-icon left>mdi-table-of-contents</v-icon> Buttons Details
                Table</v-tab
              >
              <v-tab-item class="grey darken-4">
                <v-row>
                  <v-container>
                    <v-card tile height="100%" dark outlined>
                      <v-card-title class="brown darken-1">
                        <v-row>
                          <v-col cols="12" md="12" lg="6" xl="6">
                            <h3>Manage Buttons</h3>
                          </v-col>
                          <v-col cols="12" md="12" lg="6" xl="6">
                            <v-btn
                              depressed
                              class="float-right"
                              color="white"
                              width="150"
                              outlined
                              :loading="loading"
                              :disabled="disable"
                              @click="updateButtonState"
                            >
                              <v-icon left>mdi-content-save-outline</v-icon>
                              Simpan
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            lg="4"
                            xl="4"
                            v-for="(btn, i) in buttons"
                            :key="i"
                          >
                            <br />
                            <v-card outlined>
                              <v-card-title>
                                <h4>Control {{ btn.name }}</h4>
                              </v-card-title>
                              <v-card-text>
                                <v-row>
                                  <v-col>
                                    <v-card-subtitle class="ml-n3"
                                      >Preview Button</v-card-subtitle
                                    >
                                    <v-btn block x-large color="blue accent-3">
                                      <v-icon large left>mdi-face-agent</v-icon>
                                      <span> Admin Join Reseller </span>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col>
                                    <v-card-subtitle class="ml-n3"
                                      >Buttons Configuration</v-card-subtitle
                                    >
                                    <v-autocomplete
                                      :items="color_items"
                                      :value="btn.color"
                                      v-model="btn.color"
                                      label="Color"
                                      color="light-blue accent-4"
                                      outlined
                                      rounded
                                    ></v-autocomplete>
                                    <v-text-field
                                      v-model="btn.caption"
                                      label="Caption"
                                      color="light-blue accent-4"
                                      outlined
                                      rounded
                                    ></v-text-field>
                                    <v-text-field
                                      v-model="btn.url"
                                      label="URL"
                                      color="light-blue accent-4"
                                      outlined
                                      rounded
                                    ></v-text-field>
                                    <v-autocomplete
                                      :items="state_items"
                                      :value="btn.state"
                                      v-model="btn.state"
                                      @change="changeButtonState(i, btn)"
                                      label="State"
                                      color="light-blue accent-4"
                                      outlined
                                      rounded
                                    ></v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-row>
                <br>
                <v-row>
                  <v-container>
                    <v-card dark tile outlined>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-card outlined>
                              <v-card-title class="brown darken-1">
                                <v-row>
                                  <v-col cols="12" md="12" lg="6" xl="6">
                                    <h3>Message to Visitors</h3>
                                  </v-col>
                                  <v-col cols="12" md="12" lg="6" xl="6">
                                    <v-btn
                                      outlined
                                      depressed
                                      color="white"
                                      class="float-right"
                                    >
                                      <v-icon left>mdi-content-save</v-icon>
                                      <span>Simpan Pesan</span>
                                    </v-btn>
                                  </v-col>
                                </v-row>
                              </v-card-title>
                              <v-card-text>
                                <v-card-subtitle class="ml-n3">
                                  Preview Message
                                </v-card-subtitle>
                                <v-alert
                                  icon="mdi-information-variant"
                                  border="left"
                                  colored-border
                                  elevation="2"
                                  light
                                  color="info"
                                >
                                  <p>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Rerum nobis mollitia
                                    suscipit assumenda asperiores cumque
                                    dignissimos maiores earum, necessitatibus
                                    consequatur nesciunt laboriosam voluptate
                                    dolor minus excepturi maxime nisi molestiae
                                    at!
                                  </p>
                                </v-alert>
                                <v-textarea
                                  auto-grow
                                  filled
                                  outlined
                                  label="Information Message"
                                  color="light-blue accent-1"
                                ></v-textarea>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-row>
              </v-tab-item>
              <v-tab-item class="grey darken-4">
                <v-card dark tile outlined>
                  <v-card-title class="brown darken-1">
                    Tabel Detail Tombol Join
                  </v-card-title>
                  <v-card-text>
                    <br />
                    <v-data-table
                      :headers="btn_table_headers"
                      :items="buttons"
                    ></v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      bottom
      rounded="lg"
      :color="alert_type"
      v-model="alert"
      :timeout="timeout"
    >
      <v-icon>{{ alert_icon }}</v-icon>
      {{ alert_txt }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="alert = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      tab: null,
      tab_buttons: true,
      tab_tables: false,
      loading: false,
      disable: true,
      alert: false,
      alert_txt: "",
      alert_type: "",
      alert_icon: "",
      timeout: 500,
      complete: false,
      tabs: ["Buttons", "Admin Table"],
      state_items: ["Enable", "Disable"],
      color_items: ["blue accent-3", "grey darken-2"],
      buttons: [],
      btn_val: {
        id: null,
        color: null,
        caption: null,
        url: null,
        state: null,
      },
      btn_table_headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Color", value: "color" },
        { text: "Caption", value: "caption" },
        { text: "URL", value: "url" },
        { text: "State", value: "state" },
      ],
    };
  },
  methods: {
    changeButtonState(i, value) {
      this.disable = false;
      this.btn_val.id = i + 1;
      console.log(i, value);
      if (value.state === "Disable") {
        console.log("Change the button state to disable");
        this.btn_val.color = "grey darken-2";
        this.btn_val.caption = "Admin Join Reseller (Closed)";
        this.btn_val.url = "#";
        this.btn_val.state = "Disable";
      } else {
        console.log("Chanage the button state to enable");
        this.btn_val.color = "blue accent-3";
        this.btn_val.caption = "Admin Join Reseller";
        this.btn_val.url = "https://t.me/Adm_kedasbeauty";
        this.btn_val.state = "Disable";
      }
      console.log(this.btn_val);
    },
    async getButtonsState() {
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/buttons`)
        .then((response) => {
          this.buttons = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateButtonState() {
      this.loading = true;
      this.disable = true;
      await axios
        .put(
          `${process.env.VUE_APP_KB_API_URL}/api/buttons/${this.btn_val.id}`,
          {
            color: this.btn_val.color,
            caption: this.btn_val.caption,
            url: this.btn_val.url,
            state: this.btn_val.state,
          },
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          this.complete = true;
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          this.complete = false;
        });

      if (this.complete === true) {
        this.loading = false;
        this.disable = false;
        this.alert_type = "success";
        this.alert_icon = "mdi-check";
        this.alert = true;
        this.alert_txt = "Successfuly change button state";
        location.reload();
      } else {
        this.loading = false;
        this.disable = false;
        this.alert_type = "error";
        this.alert_icon = "mdi-alert-circle-outline";
        this.alert = true;
        this.alert_txt = "Failed to change button state";
      }
    },
  },
  created() {
    this.getButtonsState();
  },
};
</script>